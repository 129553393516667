<template>
  <a-card :bordered="false" size="small" style="height: 240px">
    <template slot="title">入库概况</template>
    <template slot="extra">
      <a-space style="margin: -2px">
        <a-range-picker v-model="dateRange" :allowClear="false" @change="getData()" size="small" style="width: 200px" />
        <a-radio-group v-model="statsType" size="small" button-style="solid" @change="changeStatsType">
          <a-radio-button value="quantity">数量</a-radio-button>
          <a-radio-button value="amount">金额</a-radio-button>
        </a-radio-group>
      </a-space>
    </template>
    <div ref="lineChart"></div>
  </a-card>
</template>

<script>
import { stockInTrend } from "@/api/stats";
import { Line } from "@antv/g2plot";
import moment from "moment";

export default {
  data() {
    return {
      statsType: "quantity",
      lineChart: null,
      dateRange: [moment().subtract(7, "day"), moment()],
    };
  },
  methods: {
    initData() {
      this.lineChart = new Line(this.$refs.lineChart, {
        data: [],
        xField: "date",
        yField: "total_quantity",
        seriesField: "category",
        height: 160,
        label: {},
      });

      this.lineChart.render();
      this.getData();
    },
    getData() {
      const searchForm = {
        start_date: this.dateRange[0].format("YYYY-MM-DD"),
        end_date: this.dateRange[1].format("YYYY-MM-DD"),
      };
      stockInTrend(searchForm).then((data) => {
        this.lineChart.changeData(data);
      });
    },
    changeStatsType(event) {
      if (event.target.value == "quantity") {
        this.lineChart.update({ yField: "total_quantity" });
      } else {
        this.lineChart.update({ yField: "total_amount" });
      }
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
